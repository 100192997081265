.App {
  text-align: center;
}

body {
  
  background-color: #B30314;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a, button {
  background-color: white;
  padding: 10px; 
  border: 1px solid white;
  text-decoration: none;
  color: #B30314;
  border-radius: 5px;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  box-sizing: border-box; 
  width: 170px;
}

a:hover, button:hover {

  border: 1px solid white;
  background-color: #B30314;
  color: white; 
}

button:active {
  background-color: #B30314;
  border-color: #B30314;
  color: white;
}


a[href^="http"] {
  background-size: 16px 16px;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: white;
  margin : 15px;
  font-weight: bold;
}

.centerDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 85%;
  /* font-weight: bold; */
}
.theme-color {
  color: #B30314;
}

.cneter{
  margin: 0 auto;
}
.home-logo{
  width: 30vh;
  height: 30vh;
}

.home-logo-text{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: black;
  text-align: left;
}

.text-decoration {
  color: #B30314;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
               1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}